import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import Loading from '../../components/Loading';
import { types as messagingActions } from '../../actions/messagingActions';
import { types as contributionActions } from '../../actions/contributionActions';
import { types as validationActions } from '../../actions/validationActions';
import { types as aggregateActions } from '../../actions/aggregateActions';
import { types as budgetCategoryActions } from '../../actions/budgetCategoryActions';
import ContributionForm from '../../components/ContributionForm';
import { scrollToTop, itemDateBeforeLastFiling } from '../../helpers/util';
import { statuses, toastTypes } from '../../helpers/constants';
import {
  getLabel,
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import {
  validate,
  createPayload,
} from '../../components/ContributionForm/ContributionFormValidations';
import { getQueryParams } from '../../helpers/urlHelper';
import { useContactSearch } from '../../hooks/useContactSearch';
import {
  actions,
  addContributionReducer,
  initialState,
} from './addContributionReducer';
import {
  getBudgetCategoryOptions,
  getBudgetCategoryStatus,
  getCampaign,
  getUserSession,
  getValidations,
} from '../../selectors';
import { ConfirmContinueUpdateDialog } from '../../components/Dialogs';

export const AddContribution = ({ location, history }) => {
  const [state, localDispatch] = useReducer(
    addContributionReducer,
    initialState,
  );
  const reduxDispatch = useDispatch();
  const validations = useSelector(getValidations);
  const session = useSelector(getUserSession);
  const contributions = useSelector(state => state.contributions);
  const campaign = useSelector(getCampaign);
  const budgetCategoryStatus = useSelector(getBudgetCategoryStatus);
  const budgetCategories = useSelector(getBudgetCategoryOptions);
  const { lastFiledReportEndDate } = campaign;

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    state.election,
    state.electionYear,
    state.contactType,
    'Contribution',
  );

  const checkDuplicateCheckNumbers = (contactId, checkNumber) => {
    reduxDispatch({
      type: validationActions.VALIDATE_CHECK_NUMBER,
      data: {
        contactId,
        checkNumber,
        type: 'contribution',
      },
    });
  };

  const onContactSelected = contact => {
    localDispatch({
      type: actions.ON_CONTACT_SELECTED,
      data: { contact },
    });
    if (state.checkNumber) {
      checkDuplicateCheckNumbers(contact._id, state.checkNumber);
    }
    return null;
  };

  const setContactId = async (id) => {
    const { data: contact } = await axios.get(`/api/filer/contacts/${id}`);
    onContactSelected(contact);
  };

  const setQueryParams = () => {
    const params = getQueryParams(location.search);
    if (Object.keys(params).length) {
      localDispatch({
        type: actions.SET_QUERY_PARAMS,
        data: {
          contributionType: params.type || 0,
          reportIdRedirect: params.reportId,
          tbd: Boolean(params.tbd),
          fec: Boolean(params.fec),
          election: params.electionCycle || 0,
          frCampaignType: getFRCampaignType(campaign),
        },
      });
      if (params.contactId) {
        setContactId(params.contactId);
      }
    }
  };

  const getAggregates = contactId => {
    reduxDispatch({
      type: aggregateActions.GET_CONTRIBUTION_AGGREGATES,
      data: {
        contactId,
        excludedContributionId: '',
      },
    });
  };

  const clearAggregates = () => {
    reduxDispatch({
      type: aggregateActions.CLEAR_AGGREGATES,
    });
  };

  const clearDuplicateCheckValidations = () => {
    reduxDispatch({
      type: validationActions.CLEAR_CHECK_NUMBER_VALIDATION,
    });
  };

  useEffect(() => {
    const frCampaignType = getFRCampaignType(campaign);
    if (frCampaignType !== state.frCampaignType) {
      localDispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName: 'frCampaignType',
          value: frCampaignType,
        },
      });
    }
  }, [state.frCampaignType]);

  useEffect(() => {
    if (state?.selectedContact?._id) {
      getAggregates(state.selectedContact._id);
    } else {
      clearAggregates();
    }
  }, [state.selectedContact]);

  useEffect(() => {
    if (budgetCategoryStatus === statuses.SUCCESS) {
      localDispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName: 'budgetCategories',
          value: budgetCategories,
        },
      });
    }
  }, [budgetCategoryStatus]);

  useEffect(() => {
    if (state.electionYear === 0 && campaign) {
      const electionYear = campaign.isNonCandidateCommittee
        ? new Date().getFullYear()
        : campaign.nextElectionYear * 1 || 0;
      localDispatch({
        type: actions.SET_INITIAL_CAMPAIGN,
        data: {
          electionYear,
          isReportable: campaign.isNonCandidateCommittee,
        },
      });
    }
  }, [
    campaign.isNonCandidateCommittee,
    campaign.nextElectionYear,
    state.electionYear,
  ]);

  const getBudgetCategories = () => {
    reduxDispatch({
      type: budgetCategoryActions.SET_BUDGET_CATEGORY,
    });
  };

  useEffect(() => {
    scrollToTop();
    setQueryParams();
    getBudgetCategories();
    return () => {
      clearDuplicateCheckValidations();
      clearAggregates();
    };
  }, []);

  const clearFieldsForNew = () => {
    localDispatch({
      type: actions.CLEAR_FIELDS_FOR_NEW,
    });
  };

  const onConduitContactSelected = contact => {
    localDispatch({
      type: actions.ON_CONDUIT_CONTACT_SELECTED,
      data: { contact },
    });
  };

  const clearSelectedContact = () => {
    localDispatch({
      type: actions.CLEAR_SELECTED_CONTACT,
    });
  };

  const clearSelectedConduitContact = () => {
    localDispatch({
      type: actions.CLEAR_SELECTED_CONDUIT_CONTACT,
    });
  };

  const handleCoupledChange = (fieldName1, fieldName2) => (e, value) => {
    localDispatch({
      type: actions.HANDLE_COUPLED_CHANGE,
      data: {
        fieldName1,
        fieldName2,
        value,
      },
    });
  };

  const handleChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    if (['electionYear', 'election'].includes(fieldName)) {
      localDispatch({
        type: actions.HANDLE_CHANGE_ELECTION_DETAILS,
        data: {
          fieldName,
          value,
        },
      });
    } else if (fieldName === 'contactType') {
      localDispatch({
        type: actions.HANDLE_CHANGE_SOURCE_TYPE,
        data: {
          value,
        },
      });
    } else if (fieldName === 'paymentType') {
      localDispatch({
        type: actions.HANDLE_CHANGE_PAYMENT_TYPE,
        data: {
          value,
        },
      });
    } else if (fieldName === 'conduitContactType') {
      localDispatch({
        type: actions.HANDLE_CHANGE_CONDUIT_CONTACT_TYPE,
        data: {
          value,
        },
      });
    } else if (fieldName === 'includeConduitContact') {
      localDispatch({
        type: actions.HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT,
        data: {
          value,
        },
      });
    } else {
      localDispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName,
          value,
        },
      });
    }
  };

  const handleLoanChange = (e, value) => {
    localDispatch({
      type: actions.HANDLE_LOAN_CHANGE,
      data: {
        loanId: value.key !== undefined ? value.key : value,
      },
    });
  };

  const isOtherElection = election => {
    const result =
      Boolean(election) &&
      session.isFederal() &&
      !['Primary', 'General', 'Convention', 'Runoff', 'Recount'].includes(
        election,
      );
    return result;
  };

  const handleFederalElectionChange = (e, value) => {
    localDispatch({
      type: actions.HANDLE_FEDERAL_ELECTION_CHANGE,
      data: {
        value: value.key !== undefined ? value.key : value,
        otherElectionType:
          value.key && isOtherElection(value.key)
            ? state.otherElectionType
            : '',
      },
    });
  };

  const validateCheckNumber = () => {
    const { checkNumber, selectedContact } = state;
    if (checkNumber && selectedContact?._id) {
      checkDuplicateCheckNumbers(selectedContact._id, checkNumber);
    }
  };

  const handleChangeDateReceived = dateReceived => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'dateReceived',
        value: dateReceived,
      },
    });
  };

  const save = (addNew) => {
    const payload = createPayload(state);
    if (state.tbd) {
      payload.reportId = state.reportIdRedirect;
      reduxDispatch({
        type: contributionActions.SAVE_TBD_CONTRIBUTION,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
        },
      });
    } else if (state.fec) {
      reduxDispatch({
        type: contributionActions.SAVE_FEC_CONTRIBUTION,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
        },
      });
    } else {
      reduxDispatch({
        type: contributionActions.SAVE_CONTRIBUTION,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
        },
      });
    }

    clearDuplicateCheckValidations();

    if (addNew) {
      clearFieldsForNew();
      scrollToTop();
    }
  };

  const onSaveClick = (addNew = false) => {
    const errors = validate(state, session);
    localDispatch({ type: actions.SET_FORM_ERRORS, data: { errors } });

    if (Object.values(errors).some(e => e.length > 0)) {
      scrollToTop();
      return reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          toastType: toastTypes.ERROR,
          message:
            'There are form errors, please correct any errors to continue',
        },
      });
    }

    const isBadItemDate = itemDateBeforeLastFiling(state.dateReceived, lastFiledReportEndDate);
    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'receipt',
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return localDispatch({
        type: actions.SHOW_CONTINUE_UPDATE,
        data: {
          addNew,
          continueEditMessageList,
        },
      });
    }

    save(addNew);
  };

  const cancelContinueUpdate = () => {
    localDispatch({
      type: actions.HIDE_CONTINUE_UPDATE,
    });
  };

  const cancel = () => {
    const { reportIdRedirect, fec } = state;
    if (reportIdRedirect) {
      if (fec) {
        history.push(
          `/filer/editFECReport/${reportIdRedirect}?section=itemizedReceipts`,
        );
      } else {
        history.push(
          `/filer/editReport/${reportIdRedirect}?section=contributions`,
        );
      }
    } else {
      history.push('/filer/contributions');
    }
  };

  const onRemoveLinkedContribution = () => {
    localDispatch({ type: actions.CLEAR_LINKED_CONTRIBUTION });
  };

  const onLinkedContributionSelect = linkedContribution => {
    localDispatch({
      type: actions.ON_LINKED_CONTRIBUTION_SELECTED,
      data: { linkedContribution },
    });
    return linkedContribution;
  };

  if (contributions.saveStatus === statuses.PROCESSING) {
    return <Loading />;
  }

  if (contributions.saveStatus === statuses.NOT_STARTED) {
    const formActions = {
      handleChange,
      handleCoupledChange,
      handleLoanChange,
      handleFederalElectionChange,
      onResolveSuggestions,
      onContactSelected,
      onConduitContactSelected,
      onRenderSuggestionsItem,
      onRemoveLinkedContribution,
      onLinkedContributionSelect,
      clearSelectedContact,
      clearSelectedConduitContact,
      onBlurCheckNumber: validateCheckNumber,
      paymentInfoActions: {
        handlePaymentInfoChange: handleChange,
        handleChangeDate: handleChangeDateReceived,
      },
    };

    return (
      <div className="ContributionForm depth-1">
        <h3>{`Add ${getLabel('Contribution', session)}`}</h3>
        <ContributionForm
          campaign={campaign}
          state={state}
          actions={formActions}
          validations={validations}
          validationActions={validationActions}
          session={session}
        />
        <div className="contribution-actions-wrapper">
          <div className="actions">
            <DefaultButton
              className="contribution-btn"
              text="Cancel"
              onClick={cancel}
            />
            <PrimaryButton
              className="contribution-btn"
              text="Save"
              onClick={() => onSaveClick(false)}
            />
            <PrimaryButton
              className="contribution-btn"
              text="Save, Add New"
              onClick={() => onSaveClick(true)}
              iconProps={{
                iconName: 'Plus',
              }}
            />
          </div>
        </div>
        <ConfirmContinueUpdateDialog
          dialogHidden={state.confirmContinueUpdateHidden}
          cancel={cancelContinueUpdate}
          confirm={() => save(state.addNew)}
          messageList={state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
      </div>
    );
  }

  return null;
};

AddContribution.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default withRouter(AddContribution);
