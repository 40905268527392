import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TooltipHost } from 'office-ui-fabric-react';
import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import {
  MessageBarType,
  MessageBar,
} from 'office-ui-fabric-react/lib/MessageBar';
// import { Label } from 'office-ui-fabric-react/lib/Label';
import {
  BudgetCategoryPicker,
  ContactPicker,
  ContributionCategoryPicker,
  ContributionTypePicker,
  ContributionPicker,
  ElectionCyclePicker,
  FECElectionCyclePicker,
  LoanPicker,
  PaymentTypesPicker,
  YearPicker,
} from '../Pickers';
import { Grid, GridRow, Column, HelpIcon } from '../common';
import Tip from '../common/Tip';
import {
  contributionSourceTypes,
  FECSourceTypeCodes,
  FECSourceTypes,
  frCampaignTypes,
} from '../../helpers/constants';
import { getLabel, businessNameLabel } from '../../helpers/labelHelper';
import {
  CashPayment,
  CheckPayment,
  CreditCardPayment,
  DebitCardPayment,
} from '../PaymentSections';
import { getAggregatesContributions, getCampaign } from '../../selectors';
import AggregatesGraph from '../AggregatesGraph';
import {
  getFRCampaignType,
  canEditTransactionIdNumber,
} from '../../helpers/campaignHelper';
import { isBetaUser } from '../../helpers/sessionHelper';

import './ContributionForm.css';

const {
  FederalCandidate,
  FederalNonCandidate,
  // StateLocalCandidate,
  // StateLocalNonCandidate,
} = frCampaignTypes;

const sourceTypesRequiringFECID = [
  'Candidate Committee',
  'PAC: multicandidate',
  'PAC: non-multicandidate',
  'Party Committee: state / district / local',
  'Party Committee: national',
];

export const ContributionForm = ({
  state,
  actions,
  session,
  validations,
}) => {
  const isOtherElection = election => {
    const result =
      session.isFederal() &&
      Boolean(election) &&
      election === 'Other';
    return result;
  };
  const campaign = useSelector(getCampaign);
  const frCampaignType = getFRCampaignType(campaign);
  const canEditTransactionId = canEditTransactionIdNumber(campaign) && isBetaUser(session);

  const contributionAggregates = useSelector(getAggregatesContributions);
  const renderPaymentTypeForm = () => {
    const { paymentInfoActions, onBlurCheckNumber } = actions;
    const { checkNumberError } = validations;

    const paymentTypeMap = {
      Cash: () => (
        <CashPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountErrorMessage}
          dateReceived={state.dateReceived}
          dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
          referenceId={state.referenceId}
          sourceCode={state.sourceCode}
          mailCode={state.mailCode}
          envelopeCode={state.envelopeCode}
          externalDonorId={state.externalDonorId}
        />
      ),
      Check: () => (
        <CheckPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountErrorMessage}
          dateReceived={state.dateReceived}
          dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
          checkNumber={state.checkNumber}
          description={state.description}
          referenceId={state.referenceId}
          sourceCode={state.sourceCode}
          onBlurCheckNumber={onBlurCheckNumber}
          checkNumberError={checkNumberError}
          mailCode={state.mailCode}
          envelopeCode={state.envelopeCode}
          externalDonorId={state.externalDonorId}
        />
      ),
      'Credit Card': () => (
        <CreditCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountErrorMessage}
          dateReceived={state.dateReceived}
          dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
          description={state.description}
          referenceId={state.referenceId}
          sourceCode={state.sourceCode}
          cardLast4={state.cardLast4}
          cardType={state.cardType}
          cardholderName={state.cardholderName}
          mailCode={state.mailCode}
          envelopeCode={state.envelopeCode}
          externalDonorId={state.externalDonorId}
        />
      ),
      'Debit Card': () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountErrorMessage}
          dateReceived={state.dateReceived}
          dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
          description={state.description}
          referenceId={state.referenceId}
          sourceCode={state.sourceCode}
          mailCode={state.mailCode}
          envelopeCode={state.envelopeCode}
          externalDonorId={state.externalDonorId}
        />
      ),
      'Wire Transfer': () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountErrorMessage}
          dateReceived={state.dateReceived}
          dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
          description={state.description}
          referenceId={state.referenceId}
          sourceCode={state.sourceCode}
          mailCode={state.mailCode}
          envelopeCode={state.envelopeCode}
          externalDonorId={state.externalDonorId}
        />
      ),
      ACH: () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountErrorMessage}
          dateReceived={state.dateReceived}
          dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
          description={state.description}
          referenceId={state.referenceId}
          sourceCode={state.sourceCode}
          mailCode={state.mailCode}
          envelopeCode={state.envelopeCode}
          externalDonorId={state.externalDonorId}
        />
      ),
      Other: () => (
        <DebitCardPayment
          actions={paymentInfoActions}
          amount={state.amount}
          amountErrorMessage={state.errors.amountErrorMessage}
          dateReceived={state.dateReceived}
          dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
          description={state.description}
          referenceId={state.referenceId}
          sourceCode={state.sourceCode}
          mailCode={state.mailCode}
          envelopeCode={state.envelopeCode}
          externalDonorId={state.externalDonorId}
        />
      ),
    };
    if (paymentTypeMap[state.paymentType]) {
      return (
        <>
          <Separator />
          <div className="payment-type-section">
            {paymentTypeMap[state.paymentType]()}
          </div>
        </>
      );
    }

    if (state.contributionType === 'In Kind') {
      return (
        <div className="payment-type-section">
          <DebitCardPayment
            actions={paymentInfoActions}
            amount={state.amount}
            amountErrorMessage={state.errors.amountErrorMessage}
            dateReceived={state.dateReceived}
            dateReceivedErrorMessage={state.errors.dateReceivedErrorMessage}
            description={state.description}
            referenceId={state.referenceId}
            sourceCode={state.sourceCode}
          />
        </div>
      );
    }

    return null;
  };

  const renderConduitContactPicker = () => {
    const conduitContact = state.selectedConduitContact
      ? {
          ...state.selectedConduitContact,
          contactType: state.conduitContactType,
        }
      : null;
    return (
      <>
        {state.errors.conduitContactType && (
          <MessageBar messageBarType={MessageBarType.error}>
            {state.errors.conduitContactType}
          </MessageBar>
        )}
        <ContactPicker
          contactPickerLabel="Search Conduit Contact By Name"
          session={session}
          selectedContact={conduitContact}
          clearSelectedContact={actions.clearSelectedConduitContact}
          onResolveSuggestions={actions.onResolveSuggestions}
          onRenderSuggestionsItem={actions.onRenderSuggestionsItem}
          onItemSelected={actions.onConduitContactSelected}
          onChangeContactField={actions.handleChange}
          contactType={{
            label: 'Contact Type',
            field: 'conduitContactType',
            value: state.conduitContactType,
            required: true,
            show: true,
            options: FECSourceTypes,
          }}
          salutation={{
            label: 'Salutation',
            field: 'conduitSalutation',
            value: state.conduitSalutation,
            required: false,
            show: true,
          }}
          firstName={{
            label: 'First Name',
            field: 'conduitFirstName',
            value: state.conduitFirstName,
            required: true,
            errorMessage: state.errors.conduitFirstName,
            show: true,
          }}
          middleName={{
            label: 'Middle Name',
            field: 'conduitMiddleName',
            value: state.conduitMiddleName,
            required: false,
            show: true,
          }}
          lastName={{
            label: 'Last Name',
            field: 'conduitLastName',
            value: state.conduitLastName,
            required: true,
            show: true,
            errorMessage: state.errors.conduitLastName,
          }}
          suffix={{
            label: 'Suffix',
            field: 'conduitSuffix',
            value: state.conduitSuffix,
            required: false,
            show: true,
          }}
          occupation={{
            label: 'Occupation',
            field: 'conduitOccupation',
            value: state.conduitOccupation,
            required: false,
            show: true,
          }}
          employer={{
            label: 'Employer',
            field: 'conduitEmployer',
            value: state.conduitEmployer,
            required: false,
            show: true,
          }}
          businessName={{
            label: 'Organization Name',
            field: 'conduitBusinessName',
            value: state.conduitBusinessName,
            required: true,
            show: true,
            errorMessage: state.errors.conduitBusinessName,
          }}
          businessType={{
            label: 'Business Type',
            field: 'conduitBusinessType',
            value: state.conduitBusinessType,
            required: false,
            show: true,
          }}
          contactName={{
            label: 'Contact Name',
            field: 'conduitContactName',
            value: state.conduitContactName,
            required: false,
            show: true,
          }}
          committeeAffiliation={{
            label: 'Committee Affiliation',
            field: 'conduitCommitteeAffiliation',
            value: state.conduitCommitteeAffiliation,
            required: false,
            show: true,
          }}
          committeeFecId={{
            label: 'Conduit FEC ID',
            field: 'conduitFecId',
            value: state.conduitFecId,
            required: false,
            show: true,
          }}
          address1={{
            label: getLabel('Address Line 1', session),
            field: 'conduitAddressLine1',
            value: state.conduitAddressLine1,
            show: true,
          }}
          address2={{
            label: getLabel('Address Line 2', session),
            field: 'conduitAddressLine2',
            value: state.conduitAddressLine2,
            show: true,
          }}
          city={{
            label: 'City',
            field: 'conduitCity',
            value: state.conduitCity,
            show: true,
          }}
          state={{
            label: 'State',
            field: 'conduitState',
            value: state.conduitState,
            show: true,
          }}
          zipCode={{
            label: 'Zip Code',
            field: 'conduitZipCode',
            value: state.conduitZipCode,
            show: true,
          }}
          email={{
            label: 'Email Address',
            field: 'conduitEmail',
            value: state.conduitEmail,
            show: true,
            errorMessage: state.errors.emailErrorMessage,
          }}
          phone1={{
            label: 'Phone 1',
            field: 'conduitPhone1',
            value: state.conduitPhone1,
            show: true,
          }}
          phone2={{
            label: 'Phone 2',
            field: 'conduitPhone2',
            value: state.conduitPhone2,
            show: true,
          }}
        />
      </>
    );
  };

  const getContactSourceTypes = () => {
    let contributionContactSourceTypes = session.isFederal()
      ? FECSourceTypes
      : contributionSourceTypes;

    if (frCampaignType === FederalNonCandidate) {
      contributionContactSourceTypes = contributionContactSourceTypes.filter(c => {
        return c.key !== FECSourceTypeCodes.CAN;
      });
    }

    return contributionContactSourceTypes;
  };

  const renderContactPicker = () => {
    return (
      <>
        {state.errors.selectedContactError && (
          <MessageBar messageBarType={MessageBarType.error}>
            {state.errors.selectedContactError}
          </MessageBar>
        )}
        <ContactPicker
          session={session}
          selectedContact={state.selectedContact}
          clearSelectedContact={actions.clearSelectedContact}
          onResolveSuggestions={actions.onResolveSuggestions}
          onRenderSuggestionsItem={actions.onRenderSuggestionsItem}
          onItemSelected={actions.onContactSelected}
          onChangeContactField={actions.handleChange}
          contactType={{
            label: 'Source Type',
            field: 'contactType',
            value: state.contactType,
            required: true,
            show: true,
            options: getContactSourceTypes(),
          }}
          salutation={{
            label: 'Salutation',
            field: 'salutation',
            value: state.salutation,
            show: true,
          }}
          firstName={{
            label: 'First Name',
            field: 'firstName',
            value: state.firstName,
            required: true,
            errorMessage: state.errors.firstNameErrorMessage,
            show: true,
          }}
          middleName={{
            label: 'Middle Name',
            field: 'middleName',
            value: state.middleName,
            show: true,
          }}
          lastName={{
            label: 'Last Name',
            field: 'lastName',
            value: state.lastName,
            required: true,
            show: true,
            errorMessage: state.errors.lastNameErrorMessage,
          }}
          suffix={{
            label: 'Suffix',
            field: 'suffix',
            value: state.suffix,
            required: false,
            show: true,
          }}
          occupation={{
            label: 'Occupation',
            field: 'occupation',
            value: state.occupation,
            show: true,
          }}
          employer={{
            label: 'Employer',
            field: 'employer',
            value: state.employer,
            show: true,
          }}
          address1={{
            label: getLabel('Address Line 1', session),
            field: 'addressLine1',
            value: state.addressLine1,
            show: true,
          }}
          address2={{
            label: getLabel('Address Line 2', session),
            field: 'addressLine2',
            value: state.addressLine2,
            show: true,
          }}
          city={{
            label: 'City',
            field: 'city',
            value: state.city,
            show: true,
          }}
          state={{
            label: 'State',
            field: 'state',
            value: state.state,
            show: true,
          }}
          zipCode={{
            label: 'Zip Code',
            field: 'zipCode',
            value: state.zipCode,
            show: true,
          }}
          county={{
            label: 'County',
            field: 'county',
            value: state.county,
            show: true,
          }}
          businessName={{
            label: businessNameLabel(state.contactType),
            field: 'businessName',
            value: state.businessName,
            required: true,
            show: true,
            errorMessage: state.errors.businessNameError,
          }}
          businessType={{
            label: 'Business Type',
            field: 'businessType',
            value: state.businessType,
            show: true,
          }}
          contactName={{
            label: 'Contact Name',
            field: 'contactName',
            value: state.contactName,
            show: true,
          }}
          commonSource={{
            label: 'Common Source',
            field: 'commonSource',
            value: state.commonSource,
            show: true,
          }}
          specific={{
            label: 'Specific',
            field: 'specific',
            value: state.specific,
            show: true,
          }}
          committeeAffiliation={{
            label: 'Committee Affiliation',
            field: 'committeeAffiliation',
            value: state.committeeAffiliation,
            show: true,
          }}
          committeeFecId={{
            label: 'FEC ID',
            field: 'committeeFecId',
            value: state.committeeFecId,
            show: true,
          }}
          phone1={{
            label: 'Phone 1',
            field: 'phone1',
            value: state.phone1,
            show: true,
          }}
          phone2={{
            label: 'Phone 2',
            field: 'phone2',
            value: state.phone2,
            show: true,
          }}
          email={{
            label: 'Email',
            field: 'email',
            value: state.email,
            show: true,
            errorMessage: state.errors.emailErrorMessage,
          }}
          candidateOffice={{
            label: 'Candidate Office',
            field: 'candidateOffice',
            value: state.candidateOffice,
            show: true,
          }}
          candidateState={{
            label: 'Candidate State',
            field: 'candidateState',
            value: state.candidateState,
            show: true,
          }}
          candidateDistrict={{
            label: 'Candidate District',
            field: 'candidateDistrict',
            value: state.candidateDistrict,
            show: true,
          }}
          candidateFecId={{
            label: 'Candidate FEC ID',
            field: 'candidateFecId',
            value: state.candidateFecId,
            show: true,
          }}
          candidateFirstName={{
            label: 'Candidate First Name',
            field: 'candidateFirstName',
            value: state.candidateFirstName,
            show: true,
          }}
          candidateMiddleName={{
            label: 'Candidate Middle Name',
            field: 'candidateMiddleName',
            value: state.candidateMiddleName,
            show: true,
          }}
          candidateLastName={{
            label: 'Candidate Last Name',
            field: 'candidateLastName',
            value: state.candidateLastName,
            show: true,
          }}
        />
      </>
    );
  };

  const redesignationTip = 'When a committee receives an excessive contribution—one which exceeds the contributor’s limit or the campaign’s net debts outstanding for an election—the committee may remedy the violation by refunding the excessive amount or by seeking a redesignation or reattribution of it within 60 days. Consult with the FEC if you are unsure about the required memo entries.';
  const redesignationLabel = <span>Is Redesignation<Tip content={redesignationTip} /></span>;

  return (
    <Grid>
      {state.budgetCategories.length > 0 &&
      <GridRow>
        <Column lg={4}>
          <BudgetCategoryPicker
            label="Budget Category"
            required
            selectedKey={state.budgetCategoryId}
            onChange={actions.handleChange('budgetCategoryId')}
            errorMessage={state.errors.electionError}
            options={state.budgetCategories}
          />
        </Column>
      </GridRow>
      }
      <GridRow>
        {(frCampaignType !== FederalNonCandidate || state.contactType === FECSourceTypeCodes.PAC) &&
        <Column lg={isOtherElection(state.election) ? 2 : 4}>
          <YearPicker
            label="Election Year"
            required
            value={state.electionYear}
            onChange={actions.handleChange('electionYear')}
            errorMessage={state.errors.electionYearError}
          />
        </Column>
        }
        {(frCampaignType === FederalCandidate || state.contactType === FECSourceTypeCodes.PAC) && (
          <Column lg={isOtherElection(state.election) ? 3 : 4}>
            <FECElectionCyclePicker
              label="Receipt For"
              required
              value={state.election}
              onChange={actions.handleFederalElectionChange}
              placeholder="Select"
              errorMessage={state.errors.electionError}
            />
          </Column>
        )
        }
        {session.isFederal() === false && (
          <Column lg={isOtherElection(state.election) ? 3 : 4}>
            <ElectionCyclePicker
              label="Election"
              required
              value={state.election}
              onChange={actions.handleChange('election')}
              errorMessage={state.errors.electionError}
            />
          </Column>
        )}
        {isOtherElection(state.election) && (
          <Column lg={4}>
            <TextField
              label="Receipt For Other"
              value={state.otherElectionType}
              required
              errorMessage={state.errors.otherElectionTypeError}
              onChange={actions.handleChange('otherElectionType')}
            />
          </Column>
        )}
        <Column lg={isOtherElection(state.election) ? 3 : 4}>
          <ContributionTypePicker
            selectedKey={state.contributionType}
            onChange={actions.handleChange('contributionType')}
            errorMessage={state.errors.contributionTypeError}
            session={session}
          />
        </Column>
        {session.isFederal() && (
          <Column lg={isOtherElection(state.election) ? 3 : 4}>
            <ContributionCategoryPicker
              selectedKey={state.contributionCategory}
              onChange={actions.handleChange('contributionCategory')}
              errorMessage={state.errors.contributionCategoryTypeError}
              session={session}
            />
          </Column>
        )}
      </GridRow>
      {state.contributionType !== 'In Kind' && (
        <>
          <Separator />
          <GridRow>
            <Column>
              <PaymentTypesPicker
                required
                selectedKey={state.paymentType}
                onChange={actions.handleChange('paymentType')}
                errorMessage={state.errors.paymentTypeError}
              />
            </Column>
          </GridRow>
        </>
      )}
      <Separator />
      <GridRow>
        <Column md={(contributionAggregates.length > 0 && frCampaignType !== FederalNonCandidate) ? 6 : 12}>
          {renderContactPicker()}
        </Column>
        {(contributionAggregates.length > 0 && frCampaignType !== FederalNonCandidate) && (
          <Column md={6}>
            <AggregatesGraph
              aggregates={contributionAggregates}
              electionYear={state.electionYear}
              electionCycle={state.election}
              amount={state.amount}
            />
          </Column>
        )}
      </GridRow>
      {renderPaymentTypeForm()}
      <Separator />
      {session.isFederal() && (
        <>
          <GridRow>
            <Column>
              <h4>Conduit</h4>
              <Checkbox
                label="Include Conduit Contact"
                checked={state.includeConduitContact}
                onChange={actions.handleChange('includeConduitContact')}
              />
              {state.includeConduitContact && renderConduitContactPicker()}
            </Column>
          </GridRow>
          <Separator />
          <GridRow>
            <Column md={6}>
              <ContributionPicker
                pickerLabel="Search For Linked Contribution By Name or Amount"
                checkboxLabel="Link Contribution"
                selectedItems={state.linkedContribution}
                onItemSelected={actions.onLinkedContributionSelect}
                removeSelectedItem={actions.onRemoveLinkedContribution}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <Checkbox
                label={redesignationLabel}
                checked={state.isRedesignation}
                onChange={actions.handleCoupledChange('isRedesignation', 'memoCode')}
              />
            </Column>
            <Column>
              <Checkbox
                label="Memo Code"
                checked={state.memoCode}
                onChange={actions.handleChange('memoCode')}
                disabled={state.isRedesignation}
              />
            </Column>
          </GridRow>
          <GridRow>
            <Column>
              <TextField
                label="Memo Text"
                value={state.memoText}
                onChange={actions.handleChange('memoText')}
              />
            </Column>
          </GridRow>
          <Separator />
        </>
      )}
      {(!session.isFederal() && state.amount < 0) && (
        <GridRow>
          <Column md={6}>
            <ContributionPicker
              pickerLabel="Search For Refunded Contribution By Name or Amount"
              checkboxLabel="Refunded Contribution"
              selectedItems={state.linkedContribution}
              onItemSelected={actions.onLinkedContributionSelect}
              removeSelectedItem={actions.onRemoveLinkedContribution}
              promptingRecordId={state.promptingRecordId}
            />
          </Column>
        </GridRow>
      )}
      {(!session.isFederal() && state.contributionType === 'Credit Received on Loan') && (
        <GridRow>
          <Column md={6}>
            <LoanPicker
              value={state.loanId}
              label="Loan"
              onChange={actions.handleLoanChange}
              errorMessage={state.errors.loanErrorMessage}
            />
          </Column>
        </GridRow>
      )}
      <GridRow>
        {session.isFederal() &&
          // TODO: Broken. The contact types for federal are the following
          // state.contactType CCM 2 index.js:359
          // state.contactType CAN 2 index.js:359
          // state.contactType COM 2 index.js:359
          // state.contactType IND 2 index.js:359
          // state.contactType ORG 2 index.js:359
          // state.contactType PAC 2 index.js:359
          // state.contactType PTY
          sourceTypesRequiringFECID.includes(state.contactType) && (
            <Column md={6}>
              <TextField
                label="FEC ID Number"
                value={state.committeeFecId}
                onChange={actions.handleChange('committeeFecId')}
                required
                errorMessage={state.errors.fecIdError}
              />
            </Column>
          )}
        <Column md={3}>
          <TextField
            value={state.tags}
            onChange={actions.handleChange('tags')}
            label="Tags"
          />
        </Column>
        <Column md={3}>
          <TextField
            value={state.transactionIdNumber}
            label="Transaction Id Number"
            readOnly={!canEditTransactionId}
            onChange={canEditTransactionId ? actions.handleChange('transactionIdNumber') : null}
          />
        </Column>
      </GridRow>
      {frCampaignType === FederalCandidate && (
        <GridRow>
          <Column md={6}>
            <Checkbox
              checked={state.isCandidate}
              label="Contribution from the Candidate"
              onChange={actions.handleChange('isCandidate')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </Column>
        </GridRow>
      )}
      {session.isFederal() && (
        <GridRow>
          <Column md={6}>
            <Checkbox
              checked={state.isLoanRepayment}
              label="Is Loan Repayment"
              onChange={actions.handleChange('isLoanRepayment')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </Column>
        </GridRow>
      )}
      {state.isLoanRepayment && (
        <GridRow>
          <Column md={6}>
            <LoanPicker
              value={state.loanId}
              label="Loan"
              onChange={actions.handleLoanChange}
              errorMessage={state.errors.loanErrorMessage}
            />
          </Column>
        </GridRow>
      )}
      <GridRow>
        <Column lg={6}>
          <Checkbox
            checked={state.forceItemization}
            label="Force Itemization"
            onChange={actions.handleChange('forceItemization')}
            checkmarkIconProps={{
              iconName: 'Check',
            }}
          />
        </Column>
      </GridRow>
      {(session.isFederal() && session.role === 'SysAdmin') &&
      <GridRow>
        <Column md={6}>
          <Checkbox
            label="Do Not Report"
            checked={state.isNotReportable}
            onChange={actions.handleChange('isNotReportable')}
          />
        </Column>
      </GridRow>
      }
      {(session.isFederal() && state.contactType === 'ORG') && (
      <GridRow>
        <Column lg={6}>
          <div style={{ display: 'flex', alignItems: 'center' }}>

            <Checkbox
              checked={state.isFrozenFecFormType}
              label="Report As Individual"
              onChange={actions.handleChange('isFrozenFecFormType')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
            <TooltipHost
              content={'When selected, this will force this contribution to report as an individual contribution instead of an organization when reporting.'}
              closeDelay={750}
            >
              <HelpIcon />
            </TooltipHost>
          </div>
        </Column>
      </GridRow>)
      }
      {(session.isFederal() && (state.contactType === 'PAC' || state.contactType === 'CCM')) && (
        <GridRow>
          <Column lg={6}>
            <Checkbox
              checked={state.isTransfer}
              label="Transfer From Joint Fundraising Committee"
              onChange={actions.handleChange('isTransfer')}
              checkmarkIconProps={{
                iconName: 'Check',
              }}
            />
          </Column>
        </GridRow>)
      }
    </Grid>
  );
};

ContributionForm.propTypes = {
  state: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  validations: PropTypes.object,
  session: PropTypes.object.isRequired,
};

ContributionForm.defaultProps = {
  validations: {},
};

export default ContributionForm;
