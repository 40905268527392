import { select, takeEvery, takeLatest, call, put, debounce } from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as expenditureTypes } from '../actions/expenditureActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { types as currentCampaignTypes } from '../actions/currentCampaignActions';
import { errorToast, getServerSideErrorMessage } from '../helpers/util';
import { toastTypes } from '../helpers/constants';
import { getUserSession } from '../selectors';
import { getLabel } from '../helpers/labelHelper';

export function* isAddNewAndHasReportId(data) {
  yield put(
    push(
      `/filer/addExpenditure?reportId=${data.reportId ||
        ''}&electionCycle=${data.electionCycle || ''}&type=${data.type || ''}`,
    ),
  );
}

export function* isAddNewAndHasReportIdFec(data) {
  yield put(
    push(
      `/filer/addExpenditure?reportId=${data.reportId ||
        ''}&fec=true&type=${data.type ||
        ''}&electionCycle=${data.electionCycle || ''}`,
    ),
  );
}

export function* isNotAddNewAndHasReportId(reportId) {
  yield put(push(`/filer/editReport/${reportId}?section=expenditures`));
}

export function* isNotAddNewAndHasReportIdFec(reportId) {
  yield put(
    push(`/filer/editFECReport/${reportId}?section=itemizedDisbursements`),
  );
}

export function* addNewExpenditure(data) {
  yield put(
    push(
      `/filer/addExpenditure?electionCycle=${data.electionCycle ||
        ''}&type=${data.type || ''}`,
    ),
  );
}

export function* addNewExpenditureFec(data) {
  yield put(
    push(
      `/filer/addExpenditure?fec=true&electionCycle=${data.electionCycle ||
        ''}&type=${data.type || ''}`,
    ),
  );
}

export function* returnToExpendituresIndex() {
  yield put(push('/filer/expenditures'));
}

export function* returnToLedger() {
  yield put(push('/filer/ledger'));
}

export function* returnToVerifyReport(verifyReport) {
  yield put(push(`/filer/reports/verify/${verifyReport}`));
}

export function* handleGetExpenditures(action) {
  const { params = '' } = action.data;
  try {
    yield put({ type: expenditureTypes.GET_EXPENDITURES_PROCESSING });
    const { data: expenditures } = yield call(
      axios.get,
      `/api/filer/expenditures${params}`,
      {
        withCredentials: true,
      },
    );
    yield put({
      type: expenditureTypes.GET_EXPENDITURES_SUCCESS,
      expenditures,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: expenditureTypes.GET_EXPENDITURES_FAILURE, error });
  }
}

export function* getExpenditures() {
  yield debounce(
    500,
    expenditureTypes.GET_EXPENDITURES,
    handleGetExpenditures,
  );
}

export function* handleGetExpendituresByContact(action) {
  const { params = '', rowId } = action.data;
  try {
    yield put({ type: expenditureTypes.GET_EXPENDITURES_BY_CONTACT_PROCESSING, rowId });
    const { data: expenditures } = yield call(
      axios.get,
      `/api/filer/expendituresByContact${params}`,
      {
        withCredentials: true,
      },
    );

    yield put({
      type: expenditureTypes.GET_EXPENDITURES_BY_CONTACT_SUCCESS,
      data: { expenditures, rowId },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: expenditureTypes.GET_EXPENDITURES_BY_CONTACT_FAILURE, data: { rowId, error } });
  }
}

export function* getExpendituresByContact() {
  yield debounce(
    500,
    expenditureTypes.GET_EXPENDITURES_BY_CONTACT,
    handleGetExpendituresByContact,
  );
}

export function* handleGetExpendituresNextPage(action) {
  const { params = '' } = action.data;
  try {
    yield put({
      type: expenditureTypes.GET_EXPENDITURES_NEXT_PAGE_PROCESSING,
    });
    const { data: expenditures } = yield call(
      axios.get,
      `/api/filer/expenditures${params}`,
      {
        withCredentials: true,
      },
    );
    yield put({
      type: expenditureTypes.GET_EXPENDITURES_NEXT_PAGE_SUCCESS,
      data: { expenditures },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getExpendituresNextPage() {
  yield takeEvery(
    expenditureTypes.GET_EXPENDITURES_NEXT_PAGE,
    handleGetExpendituresNextPage,
  );
}

export function* handleSave(action) {
  try {
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    let url = '/api/filer';
    if (payload.reportId) {
      url += `/reports/${payload.reportId}/expenditures?ignoreValidation=true`;
    } else {
      url += '/expenditures?ignoreValidation=true';
    }
    yield call(axios.post, url, payload, { withCredentials: true });
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Expenditure', session)} added successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    if (addNew && reportId) {
      yield call(isAddNewAndHasReportId, {
        reportId,
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewExpenditure, {
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else {
      yield call(returnToExpendituresIndex);
    }
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_SUCCESS });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
  }
}

export function* saveExpenditure() {
  yield takeEvery(expenditureTypes.SAVE_EXPENDITURE, handleSave);
}

export function* handleSaveFec(action) {
  try {
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    let url = '/api/filer';
    if (payload.reportId) {
      url += `/reports/${payload.reportId}/expenditures?ignoreValidation=true`;
    } else {
      url += '/expenditures?ignoreValidation=true';
    }
    yield call(axios.post, url, payload, { withCredentials: true });
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Expenditure', session)} added successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdFec, {
        reportId,
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportIdFec, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewExpenditureFec, {
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else {
      yield call(returnToExpendituresIndex);
    }
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_SUCCESS });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
  }
}

export function* saveFecExpenditure() {
  yield takeEvery(expenditureTypes.SAVE_FEC_EXPENDITURE, handleSaveFec);
}

export function* handleDelete(action) {
  try {
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_PROCESSING });
    const {
      data: { id = null, reportId = null, redirect = null },
    } = action;
    if (id) {
      yield call(axios.delete, `/api/filer/expenditures/${id}`, {
        withCredentials: true,
      });
      const session = yield select(getUserSession);
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: `${getLabel('Expenditure', session)} deleted successfully!`,
          toastType: toastTypes.SUCCESS,
        },
      });
      if (reportId) {
        yield call(isNotAddNewAndHasReportId, reportId);
      } else if (redirect) {
        yield call(returnToLedger);
      } else {
        yield call(returnToExpendituresIndex);
      }
      yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
  }
}

export function* deleteExpenditure() {
  yield takeLatest(expenditureTypes.DELETE_EXPENDITURE, handleDelete);
}

export function* handleUpdate(action) {
  try {
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_PROCESSING });
    const {
      data: {
        payload,
        addNew = false,
        reportId = null,
        redirect = null,
        verifyReport = null,
      },
    } = action;

    yield call(
      axios.put,
      `/api/filer/expenditures/${payload._id}?ignoreValidation=true`,
      payload,
      { withCredentials: true },
    );
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Expenditure', session)} updated successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });

    if (verifyReport) {
      yield call(returnToVerifyReport, verifyReport);
    } else if (addNew && reportId) {
      yield call(isAddNewAndHasReportId, {
        reportId,
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewExpenditure, {
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else if (redirect) {
      yield call(returnToLedger);
    } else {
      yield call(returnToExpendituresIndex);
    }
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_FAILURE });
  }
}

export function* updateExpenditure() {
  yield takeEvery(expenditureTypes.UPDATE_EXPENDITURE, handleUpdate);
}

export function* handleUpdateFec(action) {
  try {
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_PROCESSING });
    const {
      data: {
        payload,
        addNew = false,
        reportId = null,
        redirect = null,
        verifyReport = null,
      },
    } = action;
    yield call(
      axios.put,
      `/api/filer/expenditures/${payload._id}?ignoreValidation=true`,
      payload,
      { withCredentials: true },
    );
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Expenditure', session)} updated successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });

    if (verifyReport) {
      yield call(returnToVerifyReport, verifyReport);
    } else if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdFec, {
        reportId,
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportIdFec, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewExpenditureFec, {
        type: payload.expenditureType,
        electionCycle: payload.electionCycle,
      });
    } else if (redirect) {
      yield call(returnToLedger);
    } else {
      yield call(returnToExpendituresIndex);
    }
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_FAILURE });
  }
}

export function* updateFecExpenditure() {
  yield takeEvery(expenditureTypes.UPDATE_FEC_EXPENDITURE, handleUpdateFec);
}

export function* handleDeleteFec(action) {
  try {
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_PROCESSING });
    const {
      data: { id = null, reportId = null, redirect = null },
    } = action;
    if (id) {
      yield call(axios.delete, `/api/filer/expenditures/${id}`, {
        withCredentials: true,
      });
      const session = yield select(getUserSession);
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: `${getLabel('Expenditure', session)} deleted successfully!`,
          toastType: toastTypes.SUCCESS,
        },
      });

      if (reportId) {
        yield call(isNotAddNewAndHasReportIdFec, reportId);
      } else if (redirect) {
        yield call(returnToLedger);
      } else {
        yield call(returnToExpendituresIndex);
      }
      yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: { message: error, toastType: toastTypes.ERROR },
    });
    yield put({ type: expenditureTypes.SAVE_EXPENDITURE_RESET });
  }
}

export function* deleteFecExpenditure() {
  yield takeLatest(expenditureTypes.DELETE_FEC_EXPENDITURE, handleDeleteFec);
}

export function* handleSaveFeeTransaction({ data: { payload } }) {
  try {
    const {
      data: { data: expenditure },
    } = yield call(axios.post, '/api/filer/expenditures', payload, {
      withCredentials: true,
    });
    yield put({
      type: expenditureTypes.SAVE_FEE_TRANSACTION_SUCCESS,
      data: { expenditure },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({
      type: expenditureTypes.SAVE_FEE_TRANSACTION_FAILURE,
      data: { error },
    });
  }
}

export function* saveFeeTransaction() {
  yield takeEvery(
    expenditureTypes.SAVE_FEE_TRANSACTION,
    handleSaveFeeTransaction,
  );
}
