import {
  select,
  takeEvery,
  takeLatest,
  call,
  put,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import { push } from 'connected-react-router';
import { types as contributionTypes } from '../actions/contributionActions';
import { types as messagingTypes } from '../actions/messagingActions';
import { types as currentCampaignTypes } from '../actions/currentCampaignActions';
import { getServerSideErrorMessage, errorToast } from '../helpers/util';
import { toastTypes } from '../helpers/constants';
import { getUserSession } from '../selectors';
import { getLabel } from '../helpers/labelHelper';

export function* isAddNewAndHasReportId(data) {
  yield put(
    push(
      `/filer/addContribution?reportId=${data.reportId}&type=${data.type ||
        ''}&electionCycle=${data.electionCycle || ''}`,
    ),
  );
}

export function* isAddNewAndHasReportIdTbd(reportId) {
  yield put(push(`/filer/addContribution?reportId=${reportId}&tbd=true`));
}

export function* isAddNewAndHasReportIdFec(data) {
  yield put(
    push(
      `/filer/addContribution?reportId=${data.reportId}&type=${data.type ||
        ''}&electionCycle=${data.electionCycle || ''}&fec=true`,
    ),
  );
}

export function* isNotAddNewAndHasReportId(reportId) {
  yield put(push(`/filer/editReport/${reportId}?section=contributions`));
}

export function* isNotAddNewAndHasReportIdFec(reportId) {
  yield put(push(`/filer/editFECReport/${reportId}?section=itemizedReceipts`));
}

export function* addNewContribution() {
  yield put(push('/filer/addContribution'));
}

export function* addNewContributionTbd() {
  yield put(push('/filer/addContribution?tbd=true'));
}

export function* addNewContributionFec() {
  yield put(push('/filer/addContribution?fec=true'));
}

export function* returnToContributionsIndex() {
  yield put(push('/filer/contributions'));
}

export function* returnToVerifyReport(verifyReport) {
  yield put(push(`/filer/reports/verify/${verifyReport}?refreshVerify=true`));
}

export function* returnToLedger() {
  yield put(push('/filer/ledger'));
}

export function* handleGetContributions(action) {
  const { params = '' } = action.data;
  try {
    yield put({ type: contributionTypes.GET_CONTRIBUTIONS_PROCESSING });
    const { data: contributions } = yield call(
      axios.get,
      `/api/filer/contributions${params}`,
      {
        withCredentials: true,
      },
    );

    yield put({
      type: contributionTypes.GET_CONTRIBUTIONS_SUCCESS,
      contributions,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: contributionTypes.GET_CONTRIBUTIONS_FAILURE, error });
  }
}

export function* getContributions() {
  yield debounce(
    500,
    contributionTypes.GET_CONTRIBUTIONS,
    handleGetContributions,
  );
}

export function* handleGetContributionsByContact(action) {
  const { params = '', rowId } = action.data;
  try {
    yield put({ type: contributionTypes.GET_CONTRIBUTIONS_BY_CONTACT_PROCESSING, rowId });
    const { data: contributions } = yield call(
      axios.get,
      `/api/filer/contributionsByContact${params}`,
      {
        withCredentials: true,
      },
    );

    yield put({
      type: contributionTypes.GET_CONTRIBUTIONS_BY_CONTACT_SUCCESS,
      data: { contributions, rowId },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: contributionTypes.GET_CONTRIBUTIONS_BY_CONTACT_FAILURE, data: { rowId, error } });
  }
}

export function* getContributionsByContact() {
  yield debounce(
    500,
    contributionTypes.GET_CONTRIBUTIONS_BY_CONTACT,
    handleGetContributionsByContact,
  );
}

export function* handleGetContributionsNextPage(action) {
  const { params = '' } = action.data;
  try {
    yield put({
      type: contributionTypes.GET_CONTRIBUTIONS_NEXT_PAGE_PROCESSING,
    });
    const { data: contributions } = yield call(
      axios.get,
      `/api/filer/contributions${params}`,
      {
        withCredentials: true,
      },
    );
    yield put({
      type: contributionTypes.GET_CONTRIBUTIONS_NEXT_PAGE_SUCCESS,
      data: { contributions },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* getContributionsNextPage() {
  yield takeEvery(
    contributionTypes.GET_CONTRIBUTIONS_NEXT_PAGE,
    handleGetContributionsNextPage,
  );
}

export function* handleSave(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    let url = '/api/filer';
    if (payload.reportId) {
      url += `/reports/${payload.reportId}/contributions?ignoreValidation=true`;
    } else {
      url += '/contributions?ignoreValidation=true';
    }

    yield call(axios.post, url, payload, { withCredentials: true });
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Contribution', session)} added successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    if (addNew && reportId) {
      yield call(isAddNewAndHasReportId, {
        reportId,
        type: payload.contributionType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewContribution);
    } else {
      yield call(returnToContributionsIndex);
    }

    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
  }
}

export function* save() {
  yield takeEvery(contributionTypes.SAVE_CONTRIBUTION, handleSave);
}

export function* handleSaveTbd(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    const url = '/api/filer/contributions?ignoreValidation=true';

    yield call(axios.post, url, payload, { withCredentials: true });
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Contribution', session)} added successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });

    if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdTbd, reportId);
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewContributionTbd);
    } else {
      yield call(returnToContributionsIndex);
    }

    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_SUCCESS });
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
  }
}

export function* saveTbd() {
  yield takeLatest(contributionTypes.SAVE_TBD_CONTRIBUTION, handleSaveTbd);
}

export function* handleUpdate(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const {
      data: {
        payload,
        addNew = false,
        reportId = null,
        redirect = null,
        verifyReport = null,
      },
    } = action;

    yield call(
      axios.put,
      `/api/filer/contributions/${payload._id}?ignoreValidation=true`,
      payload,
      { withCredentials: true },
    );
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Contribution', session)} updated successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
    if (verifyReport) {
      yield call(returnToVerifyReport, verifyReport);
    } else if (addNew && reportId) {
      yield call(isAddNewAndHasReportId, {
        reportId,
        type: payload.contributionType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewContribution);
    } else if (redirect === 'ledger') {
      yield call(returnToLedger);
    } else {
      yield call(returnToContributionsIndex);
    }

    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_FAILURE });
  }
}

export function* handleUpdateTbd(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const { payload, addNew = false, reportId = false } = action.data;
    yield call(
      axios.put,
      `/api/filer/contributions/${payload._id}?ignoreValidation=true`,
      payload,
      { withCredentials: true },
    );
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Contribution', session)} updated successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });

    if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdTbd, {
        reportId,
        type: payload.contributionType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportId, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewContributionTbd);
    } else {
      yield call(returnToContributionsIndex);
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* updateTbd() {
  yield takeEvery(contributionTypes.UPDATE_CONTRIBUTION_TBD, handleUpdateTbd);
}

export function* update() {
  yield takeEvery(contributionTypes.UPDATE_CONTRIBUTION, handleUpdate);
}

export function* handleDelete(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const {
      data: { id, reportId = null, redirect = null },
    } = action;
    if (id) {
      yield call(axios.delete, `/api/filer/contributions/${id}`, {
        withCredentials: true,
      });
      const session = yield select(getUserSession);
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: `${getLabel('Contribution', session)} deleted successfully!`,
          toastType: toastTypes.SUCCESS,
        },
      });
      if (reportId) {
        yield call(isNotAddNewAndHasReportId, reportId);
      } else if (redirect === 'ledger') {
        yield call(returnToLedger);
      } else {
        yield call(returnToContributionsIndex);
      }

      yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
  }
}

export function* handleDeleteFec(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const {
      data: { id, reportId = null, redirect = null },
    } = action;
    if (id) {
      yield call(axios.delete, `/api/filer/contributions/${id}`, {
        withCredentials: true,
      });
      const session = yield select(getUserSession);
      yield put({
        type: messagingTypes.SET_TOAST,
        data: {
          message: `${getLabel('Contribution', session)} deleted successfully!`,
          toastType: toastTypes.SUCCESS,
        },
      });

      if (reportId) {
        yield call(isNotAddNewAndHasReportIdFec, reportId);
      } else if (redirect === 'ledger') {
        yield call(returnToLedger);
      } else {
        yield call(returnToContributionsIndex);
      }

      yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
      yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
    }
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
  }
}

export function* deleteContribution() {
  yield takeLatest(contributionTypes.DELETE_CONTRIBUTION, handleDelete);
}

export function* deleteFecContribution() {
  yield takeLatest(contributionTypes.DELETE_FEC_CONTRIBUTION, handleDeleteFec);
}

export function* handleSaveFec(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const {
      data: { payload, addNew = false, reportId = null },
    } = action;
    const url = '/api/filer/contributions?ignoreValidation=true';
    yield call(axios.post, url, payload, { withCredentials: true });
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Contribution', session)} added successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdFec, {
        reportId,
        type: payload.contributionType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportIdFec, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewContributionFec);
    } else {
      yield call(returnToContributionsIndex);
    }

    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_SUCCESS });
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });
    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* saveFecContribution() {
  yield takeEvery(contributionTypes.SAVE_FEC_CONTRIBUTION, handleSaveFec);
}

export function* handleUpdateFec(action) {
  try {
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_PROCESSING });
    const {
      data: {
        payload,
        addNew = false,
        reportId = null,
        redirect = null,
        verifyReport = null,
      },
    } = action;
    yield call(
      axios.put,
      `/api/filer/contributions/${payload._id}?ignoreValidation=true`,
      payload,
      { withCredentials: true },
    );
    const session = yield select(getUserSession);
    yield put({
      type: messagingTypes.SET_TOAST,
      data: {
        message: `${getLabel('Contribution', session)} updated successfully!`,
        toastType: toastTypes.SUCCESS,
      },
    });
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_RESET });

    if (verifyReport) {
      yield call(returnToVerifyReport, verifyReport);
    } else if (addNew && reportId) {
      yield call(isAddNewAndHasReportIdFec, {
        reportId,
        type: payload.contributionType,
        electionCycle: payload.electionCycle,
      });
    } else if (!addNew && reportId) {
      yield call(isNotAddNewAndHasReportIdFec, reportId);
    } else if (addNew && !reportId) {
      yield call(addNewContributionFec);
    } else if (redirect === 'ledgers') {
      yield call(returnToLedger);
    } else {
      yield call(returnToLedger);
    }

    yield put({ type: currentCampaignTypes.GET_CURRENT_CAMPAIGN_FINANCE });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
    yield put({ type: contributionTypes.SAVE_CONTRIBUTION_FAILURE });
  }
}

export function* updateFecContribution() {
  yield takeEvery(contributionTypes.UPDATE_FEC_CONTRIBUTION, handleUpdateFec);
}

export function* handleSaveInterestTransaction({ data: { payload } }) {
  try {
    const {
      data: contribution,
    } = yield call(axios.post, '/api/filer/contributions?ignoreValidation=true', payload, {
      withCredentials: true,
    });

    yield put({
      type: contributionTypes.SAVE_INTEREST_TRANSACTION_SUCCESS,
      data: { contribution },
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put(errorToast(error));
  }
}

export function* saveInterestTransaction() {
  yield takeEvery(
    contributionTypes.SAVE_INTEREST_TRANSACTION,
    handleSaveInterestTransaction,
  );
}
