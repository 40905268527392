import {
  call,
  put,
  debounce,
} from 'redux-saga/effects';
import axios from 'axios';
import { types as contributionSummaryTypes } from '../actions/contributionSummaryActions';
import { getServerSideErrorMessage } from '../helpers/util';

export function* handleGetContributionsSummary(action) {
  const { params = '' } = action.data;
  try {
    yield put({ type: contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY_PROCESSING });
    const { data: contributionsSummary } = yield call(
      axios.get,
      `/api/filer/contributions/summaries${params}`,
      {
        withCredentials: true,
      },
    );

    yield put({
      type: contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY_SUCCESS,
      contributionsSummary,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY_FAILURE, error });
  }
}

export function* getContributionsSummary() {
  yield debounce(
    500,
    contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY,
    handleGetContributionsSummary,
  );
}

export function* handleGetContributionsSummaryNextPage(action) {
  const { params = '' } = action.data;
  try {
    yield put({ type: contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_PROCESSING });
    const { data: contributionsSummary } = yield call(
      axios.get,
      `/api/filer/contributions/summaries${params}`,
      {
        withCredentials: true,
      },
    );

    yield put({
      type: contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_SUCCESS,
      contributionsSummary,
    });
  } catch (e) {
    const error = getServerSideErrorMessage(e);
    yield put({ type: contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE_FAILURE, error });
  }
}

export function* getContributionsSummaryNextPage() {
  yield debounce(
    500,
    contributionSummaryTypes.GET_CONTRIBUTIONS_SUMMARY_NEXT_PAGE,
    handleGetContributionsSummaryNextPage,
  );
}
