import React, { Fragment, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  DefaultButton,
  PrimaryButton,
} from 'office-ui-fabric-react/lib/Button';
import axios from 'axios';
import { types as messagingActions } from '../../actions/messagingActions';
import { types as contributionActions } from '../../actions/contributionActions';
import { types as validationActions } from '../../actions/validationActions';
import { types as aggregateActions } from '../../actions/aggregateActions';
import { types as budgetCategoryActions } from '../../actions/budgetCategoryActions';
import ContributionForm from '../../components/ContributionForm';
import Loading from '../../components/Loading';
import { scrollToTop, getServerSideErrorMessage, itemDateBeforeLastFiling } from '../../helpers/util';
import { statuses, toastTypes } from '../../helpers/constants';
import { getQueryParams } from '../../helpers/urlHelper';
import {
  getLabel,
  convertItemUpdateErrorsToMessageList,
  itemUpdateWarning,
} from '../../helpers/labelHelper';
import { getFRCampaignType } from '../../helpers/campaignHelper';
import {
  validate,
  createPayload,
} from '../../components/ContributionForm/ContributionFormValidations';
import { ConfirmDeleteFromReportDialog, ConfirmContinueUpdateDialog } from '../../components/Dialogs';
import { useContactSearch } from '../../hooks/useContactSearch';
import {
  actions,
  initialState,
  editContributionReducer,
} from './editContributionReducer';
import {
  getBudgetCategoryOptions,
  getBudgetCategoryStatus,
  getCampaign,
  getUserSession,
  getValidations,
} from '../../selectors';

export const EditContribution = ({ location, history, match }) => {
  const reduxDispatch = useDispatch();
  const [state, localDispatch] = useReducer(
    editContributionReducer,
    initialState,
  );
  const session = useSelector(getUserSession);
  const validations = useSelector(getValidations);
  const campaign = useSelector(getCampaign);
  const budgetCategoryStatus = useSelector(getBudgetCategoryStatus);
  const budgetCategories = useSelector(getBudgetCategoryOptions);

  const { lastFiledReportEndDate } = campaign;

  const getLinkedContributionFromTransactionId = async (linkedTransactionId) => {
    try {
      const { data } = await axios.get(`/api/filer/contributions/${linkedTransactionId}`, {
        withCredentials: true,
      });
      if (data.length) {
        const [contribution] = data;
        localDispatch({
          type: actions.ON_LINKED_CONTRIBUTION_SELECTED,
          data: { linkedContribution: contribution },
        });
      }
    } catch (e) {
      const error = getServerSideErrorMessage(e);
      reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          message: error,
          toastType: toastTypes.ERROR,
        },
      });
    }
  };

  const getContribution = async () => {
    const { id } = match.params;
    const params = getQueryParams(location.search);
    if (id) {
      try {
        const { data } = await axios.get(`/api/filer/contributions/${id}`, {
          withCredentials: true,
        });
        if (data.length) {
          const [contribution] = data;
          localDispatch({
            type: actions.GET_CONTRIBUTION_FOR_EDIT_SUCCESS,
            data: {
              contribution,
              params,
              isFederal: session.isFederal(),
              frCampaignType: getFRCampaignType(campaign),
            },
          });
          if (contribution.linkedTransactionId) {
            await getLinkedContributionFromTransactionId(contribution.linkedTransactionId);
          }
        }
      } catch (e) {
        const error = getServerSideErrorMessage(e);
        reduxDispatch({
          type: messagingActions.SET_TOAST,
          data: {
            message: error,
            toastType: toastTypes.ERROR,
          },
        });
      }
    }
  };

  const checkDuplicateCheckNumbers = (contactId, checkNumber) => {
    reduxDispatch({
      type: validationActions.VALIDATE_CHECK_NUMBER,
      data: {
        contactId,
        checkNumber,
        type: 'contribution',
      },
    });
  };

  const clearDuplicateCheckValidations = () => {
    reduxDispatch({
      type: validationActions.CLEAR_CHECK_NUMBER_VALIDATION,
    });
  };

  const getAggregates = (contactId, excludedContributionId) => {
    reduxDispatch({
      type: aggregateActions.GET_CONTRIBUTION_AGGREGATES,
      data: {
        contactId,
        excludedContributionId,
      },
    });
  };

  const clearAggregates = () => {
    reduxDispatch({
      type: aggregateActions.CLEAR_AGGREGATES,
    });
  };

  useEffect(() => {
    if (match.params.id && state?.selectedContact?._id) {
      getAggregates(state.selectedContact._id, match.params.id);
    } else {
      clearAggregates();
    }
  }, [state.selectedContact, match.params.id]);

  useEffect(() => {
    if (budgetCategoryStatus === statuses.SUCCESS) {
      localDispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName: 'budgetCategories',
          value: budgetCategories,
        },
      });
    }
  }, [budgetCategoryStatus]);

  const getBudgetCategories = () => {
    reduxDispatch({
      type: budgetCategoryActions.SET_BUDGET_CATEGORY,
    });
  };

  useEffect(() => {
    scrollToTop();
    getContribution();
    getBudgetCategories();
    return () => {
      clearDuplicateCheckValidations();
      clearAggregates();
    };
  }, []);

  const clearSelectedContact = () => {
    localDispatch({
      type: actions.CLEAR_SELECTED_CONTACT,
    });
  };

  const clearSelectedConduitContact = () => {
    localDispatch({
      type: actions.CLEAR_SELECTED_CONDUIT_CONTACT,
    });
  };

  const onContactSelected = contact => {
    localDispatch({
      type: actions.ON_CONTACT_SELECTED,
      data: {
        contact,
      },
    });
    if (state.checkNumber && contact._id) {
      checkDuplicateCheckNumbers(contact._id, state.checkNumber);
    }
    return null;
  };

  const onConduitContactSelected = contact => {
    localDispatch({
      type: actions.ON_CONDUIT_CONTACT_SELECTED,
      data: {
        contact,
      },
    });

    return null;
  };

  const [onResolveSuggestions, onRenderSuggestionsItem] = useContactSearch(
    state.election,
    state.electionYear,
    state.contactType,
    'Contribution',
  );

  const handleChangeDateReceived = dateReceived => {
    localDispatch({
      type: actions.HANDLE_CHANGE,
      data: {
        fieldName: 'dateReceived',
        value: dateReceived,
      },
    });
  };

  const handleCoupledChange = (fieldName1, fieldName2) => (e, value) => {
    localDispatch({
      type: actions.HANDLE_COUPLED_CHANGE,
      data: {
        fieldName1,
        fieldName2,
        value,
      },
    });
  };

  const handleChange = fieldName => (e, option) => {
    const value = option.key !== undefined ? option.key : option;
    if (['electionYear', 'election'].includes(fieldName)) {
      localDispatch({
        type: actions.HANDLE_CHANGE_ELECTION_DETAILS,
        data: {
          fieldName,
          value,
        },
      });
    } else if (fieldName === 'contactType') {
      localDispatch({
        type: actions.HANDLE_CHANGE_SOURCE_TYPE,
        data: {
          value,
        },
      });
    } else if (fieldName === 'conduitContactType') {
      localDispatch({
        type: actions.HANDLE_CHANGE_CONDUIT_CONTACT_TYPE,
        data: {
          value,
        },
      });
    } else if (fieldName === 'includeConduitContact') {
      localDispatch({
        type: actions.HANDLE_CHANGE_INCLUDE_CONDUIT_CONTACT,
        data: {
          value,
        },
      });
    } else {
      localDispatch({
        type: actions.HANDLE_CHANGE,
        data: {
          fieldName,
          value,
        },
      });
    }
  };

  const handleLoanChange = (e, value) => {
    localDispatch({
      type: actions.HANDLE_LOAN_CHANGE,
      data: {
        loanId: value.key !== undefined ? value.key : value,
      },
    });
  };

  const handleFederalElectionChange = (e, value) => {
    localDispatch({
      type: actions.HANDLE_CHANGE_FEDERAL_ELECTION,
      data: {
        value: value.key !== undefined ? value.key : value,
      },
    });
  };

  const validateCheckNumber = () => {
    const { checkNumber, selectedContact } = state;
    if (checkNumber && selectedContact?._id) {
      checkDuplicateCheckNumbers(selectedContact._id, checkNumber);
    }
  };

  const cancel = () => {
    if (state.reportIdRedirect) {
      if (state.fec) {
        history.push(
          `/filer/editFECReport/${state.reportIdRedirect}?section=itemizedReceipts`,
        );
      } else {
        history.push(
          `/filer/editReport/${state.reportIdRedirect}?section=contributions`,
        );
      }
    } else if (state.redirect === 'ledgers') {
      history.push('/filer/ledger');
    } else {
      history.push('/filer/contributions');
    }
  };

  const onRemoveLinkedContribution = () => {
    localDispatch({
      type: actions.CLEAR_LINKED_CONTRIBUTION,
    });
  };

  const onLinkedContributionSelect = linkedContribution => {
    localDispatch({
      type: actions.ON_LINKED_CONTRIBUTION_SELECTED,
      data: { linkedContribution },
    });
    return linkedContribution;
  };

  const deleteContribution = id => {
    if (state.fec) {
      reduxDispatch({
        type: contributionActions.DELETE_FEC_CONTRIBUTION,
        data: {
          id,
          reportId: state.reportIdRedirect,
          redirect: state.redirect,
        },
      });
    } else {
      reduxDispatch({
        type: contributionActions.DELETE_CONTRIBUTION,
        data: {
          id,
          reportId: state.reportIdRedirect,
          redirect: state.redirect,
        },
      });
    }
  };

  const confirmDeleteContribution = () => {
    const { isItemFiled, reconciliationId, depositId, linkedTo } = state;
    const { id } = match.params;
    if (id) {
      if (isItemFiled || reconciliationId || depositId) {
        return localDispatch({
          type: actions.SHOW_CONFIRM_DELETE_MODAL,
        });
      }

      if (linkedTo && session.isFederal()) {
        return localDispatch({
          type: actions.SHOW_CONFIRM_LINKED_DELETE_MODAL,
        });
      }
      deleteContribution(id);
    }
  };

  const cancelDelete = () => {
    localDispatch({
      type: actions.HIDE_CONFIRM_DELETE_MODAL,
    });
  };

  const cancelLinkDelete = () => {
    localDispatch({
      type: actions.HIDE_CONFIRM_LINKED_DELETE_MODAL,
    });
  };

  const cancelContinueUpdate = () => {
    localDispatch({
      type: actions.HIDE_CONTINUE_UPDATE,
    });
  };

  const save = addNew => {
    const payload = createPayload(state, match.params.id);

    if (state.tbd) {
      payload.reportId = state.reportIdRedirect;
      reduxDispatch({
        type: contributionActions.UPDATE_CONTRIBUTION_TBD,
        data: {
          payload,
          addNew,
          report: state.reportIdRedirect,
        },
      });
    } else if (state.fec) {
      reduxDispatch({
        type: contributionActions.UPDATE_FEC_CONTRIBUTION,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
          redirect: state.redirect,
          verifyReport: state.verifyReport,
        },
      });
    } else {
      reduxDispatch({
        type: contributionActions.UPDATE_CONTRIBUTION,
        data: {
          payload,
          addNew,
          reportId: state.reportIdRedirect,
          redirect: state.redirect,
          verifyReport: state.verifyReport,
        },
      });

      clearDuplicateCheckValidations();

      if (addNew) {
        localDispatch({
          type: actions.CLEAR_FIELDS_FOR_NEW,
        });
        scrollToTop();
      }
    }
  };

  const onSaveClick = addNew => {
    const errors = validate(state, session);
    const { isItemFiled, reconciliationId, depositId } = state;
    const isBadItemDate = itemDateBeforeLastFiling(state.dateReceived, lastFiledReportEndDate);

    localDispatch({
      type: actions.SET_FORM_ERRORS,
      data: {
        errors,
      },
    });

    if (Object.values(errors).some(e => e.length > 0)) {
      scrollToTop();
      return reduxDispatch({
        type: messagingActions.SET_TOAST,
        data: {
          toastType: toastTypes.ERROR,
          message:
            'There are form errors, please correct any errors to continue',
        },
      });
    }

    const continueEditMessageList = convertItemUpdateErrorsToMessageList({
      itemName: 'receipt',
      isItemFiled,
      reconciliationId,
      depositId,
      isBadItemDate,
    });

    if (continueEditMessageList.length > 0) {
      return localDispatch({
        type: actions.SHOW_CONTINUE_UPDATE,
        data: {
          addNew,
          continueEditMessageList,
        },
      });
    }

    save(addNew);
  };

  if (state.status === statuses.PROCESSING) {
    return <Loading />;
  }

  if (state.status === statuses.SUCCESS) {
    const formActions = {
      handleChange,
      handleCoupledChange,
      handleLoanChange,
      handleFederalElectionChange,
      onResolveSuggestions,
      onContactSelected,
      onConduitContactSelected,
      onRenderSuggestionsItem,
      onRemoveLinkedContribution,
      onLinkedContributionSelect,
      clearSelectedContact,
      clearSelectedConduitContact,
      onBlurCheckNumber: validateCheckNumber,
      paymentInfoActions: {
        handlePaymentInfoChange: handleChange,
        handleChangeDate: handleChangeDateReceived,
      },
    };

    return (
      <div className="ContributionForm depth-1">
        <h3>{`Edit ${getLabel('Contribution', session)}`}</h3>
        <ContributionForm
          state={state}
          actions={formActions}
          validations={validations}
          session={session}
        />
        <div className="contribution-actions-wrapper">
          <div className="actions">
            <DefaultButton
              text="Cancel"
              onClick={cancel}
              className="contribution-btn"
            />
            <PrimaryButton
              text="Save"
              onClick={() => onSaveClick(false)}
              className="contribution-btn"
            />
            {!state.verifyReport && (
              <Fragment>
                <PrimaryButton
                  text="Save, Add New"
                  onClick={() => onSaveClick(true)}
                  className="contribution-btn"
                  iconProps={{
                    iconName: 'Plus',
                  }}
                />
                <DefaultButton
                  style={{
                    backgroundColor: '#a80000',
                    color: '#fff',
                  }}
                  className="contribution-btn"
                  text="Delete"
                  onClick={confirmDeleteContribution}
                />
              </Fragment>
            )}
          </div>
        </div>
        <ConfirmDeleteFromReportDialog
          dialogHidden={state.confirmDeleteHidden}
          cancel={cancelDelete}
          confirm={() => deleteContribution(match.params.id)}
          itemType={getLabel('Contribution', session)}
          message="Deleting an item associated to a report or deposited/reconciled can make accurate reporting and balancing difficult or impossible."
        />
        <ConfirmDeleteFromReportDialog
          dialogHidden={state.confirmLinkedContributionDeleteHidden}
          cancel={cancelLinkDelete}
          confirm={() => deleteContribution(match.params.id)}
          itemType={getLabel('Contribution', session)}
          message="This contribution is referenced from another contribution through a link. Removing this contribution will remove any links that reference this contribution."
        />
        <ConfirmContinueUpdateDialog
          dialogHidden={state.confirmContinueUpdateHidden}
          cancel={cancelContinueUpdate}
          confirm={() => save(state.addNew)}
          messageList={state.continueEditMessageList}
          instruction={itemUpdateWarning}
        />
      </div>
    );
  }

  return null;
};

EditContribution.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(EditContribution);
