import { statuses } from '../../helpers/constants';

export const initialState = {
  transactionIdNumber: '',
  electionYear: 0,
  election: 0,
  otherElectionType: '',
  contributionType: 0,
  contributionCategory: 'Contribution',
  contactType: 0,
  paymentType: 0,
  selectedContact: null,
  selectedConduitContact: null,
  salutation: 'none',
  firstName: '',
  middleName: '',
  lastName: '',
  suffix: '',
  employer: '',
  occupation: '',
  businessName: '',
  businessType: 0,
  contactName: '',
  commonSource: '',
  specific: '',
  committeeAffiliation: 0,
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: 0,
  zipCode: '',
  county: '',
  email: '',
  phone1: '',
  phone2: '',
  amount: '',
  dateReceived: '',
  referenceId: '',
  sourceCode: '',
  depositId: '',
  checkNumber: '',
  description: '',
  cardLast4: '',
  cardType: 0,
  cardholderName: '',
  forceItemization: false,
  isNotReportable: false,
  frozenFecFormType: '',
  isFrozenFecFormType: false,
  isItemFiled: false,
  reconciliationId: null,
  reconciled: null,
  tags: '',
  isCandidate: false,
  isLoanRepayment: false,
  committeeFecId: '',
  budgetCategoryId: '',
  budgetCategories: [],
  redirect: '',
  verifyReport: false,
  tbd: false,
  fec: false,
  reportIdRedirect: null,
  confirmDeleteHidden: true,
  confirmLinkedContributionDeleteHidden: true,
  confirmContinueUpdateHidden: true,
  linkedTo: null,
  status: statuses.NOT_STARTED,
  isReportable: false,
  isTransfer: null,
  isRedesignation: false,
  memoCode: false,
  memoText: '',
  includeConduitContact: false,
  conduitContactType: 0,
  conduitContactId: null,
  conduitSalutation: 'none',
  conduitFirstName: '',
  conduitMiddleName: '',
  conduitLastName: '',
  conduitSuffix: '',
  conduitEmployer: '',
  conduitOccupation: '',
  conduitBusinessName: '',
  conduitBusinessType: 0,
  conduitContactName: '',
  conduitCommitteeAffiliation: 0,
  conduitCommonSource: '',
  conduitSpecific: '',
  conduitEmail: '',
  conduitPhone1: '',
  conduitPhone2: '',
  conduitFecId: '',
  conduitAddressLine1: '',
  conduitAddressLine2: '',
  conduitCity: '',
  conduitState: 0,
  conduitZipCode: '',
  conduitCounty: '',
  candidateOffice: '',
  candidateState: 0,
  candidateDistrict: '',
  candidateFecId: '',
  candidateFirstName: '',
  candidateMiddleName: '',
  candidateLastName: '',
  linkedContribution: null,
  loanId: 0,
  addNew: false,
  continueEditMessageList: [],
  mailCode: '',
  envelopeCode: '',
  externalDonorId: '',
};

export const errors = {
  electionYearError: '',
  electionError: '',
  firstNameErrorMessage: '',
  lastNameErrorMessage: '',
  businessNameError: '',
  emailErrorMessage: '',
  fecIdError: '',
  otherElectionTypeError: '',
  contributionTypeError: '',
  contributionCategoryError: '',
  paymentTypeError: '',
  amountErrorMessage: '',
  dateReceivedErrorMessage: '',
  selectedContactError: '',
  conduitFirstName: '',
  conduitLastName: '',
  conduitBusinessName: '',
  conduitContactType: '',
};
